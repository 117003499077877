import React, { useRef, useCallback } from "react";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "./affix/AutoAffix";
import { Link } from "./link";
import utils from "../../gatsby/utils";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const SliderCompetence = () => {
  const { competence } = useStaticQuery(
    graphql`
      query {
        competence: allContentfulCompetenceCenter(
          filter: { node_locale: { eq: "it" } }
          sort: { fields: position, order: ASC }
          limit: 10
        ) {
          nodes {
            titolo
            abstract
            link
            immagine {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, quality: 60)
            }
          }
        }
      }
    `
  );
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <div className="slider-competence d-flex">
      <div className="slider-competence__wrap-slide">
        <div ref={scrollRef}>
          {competence.nodes.map((item, i) => (
            <div key={i} id={utils.slugify(item.titolo)} className="slider-competence__slide">
              <div className="box-competence">
                <div className="box-competence__text">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="box-competence__label">Competence Center</div>
                        {item.titolo && <div className="box-competence__title">{item.titolo}</div>}
                        {item.abstract && (
                          <div className="box-competence__abstract">{item.abstract}</div>
                        )}
                        <Link to={item.link} className="btn-link btn-link--secondary">
                          Scopri di più
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-competence__img">
                  <GatsbyImage image={getImage(item.immagine)} alt={item.titolo} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <AutoAffix
          affixClassName="slider-competence__dots slider-competence__dots--affix"
          topClassName="slider-competence__dots slider-competence__dots--affix-top"
          bottomClassName="slider-competence__dots slider-competence__dots--affix-bottom"
          viewportOffsetTop={74}
          container={getScrollContainer}
        >
          <div>
            <nav className="slider-competence__anchor">
              <Scrollspy
                items={competence.nodes.map((item) => utils.slugify(item.titolo))}
                offset={-74}
                className="slider-competence__anchor-list"
                currentClassName="slider-competence__anchor-item--active"
              >
                {competence.nodes.map((item, i) => (
                  <li key={i} className="slider-competence__anchor-item">
                    <ScrollLink
                      href={`#${utils.slugify(item.titolo)}`}
                      to={utils.slugify(item.titolo)}
                      className="slider-competence__anchor-link"
                      title={`Passa alla slide ${i + 1}`}
                    >
                      <span className="invisible">
                        slide {i + 1} - {item.titolo}
                      </span>
                    </ScrollLink>
                  </li>
                ))}
              </Scrollspy>
            </nav>
          </div>
        </AutoAffix>
      </div>
    </div>
  );
};

export default SliderCompetence;
